var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-news"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("用强有力的奖励政策 加速科技成果转化落地")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"pagination"},[_c('div',{staticClass:"a-more"},[_c('span',{on:{"click":function($event){return _vm.$router.push('/indexOs/newsDetails3')}}},[_vm._v("上一条：大科城软件产业联盟拍了拍你，新云网智慧亮剑！")]),_c('span',{on:{"click":function($event){return _vm.$router.push('/indexOs/newsDetails5')}}},[_vm._v("下一条：爱心点燃希望 真情传递温暖 ——新云网向花垣县双龙镇的十八洞小学进行捐赠")])]),_c('el-button',{attrs:{"plain":""},on:{"click":function($event){return _vm.backGo()}}},[_vm._v(" 返回 ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-setting"},[_c('h3',{staticClass:"time"},[_vm._v(" 发布时间： 2020-08-24 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"53px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news4/news1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 用强有力的奖励政策加速科技成果转化落地，岳麓山国家大学科技城通过盘活人才、技术、资金、市场等资源，对于重大科技成果转化项目和院士衔接的成果转化项目，最高可以给予一千万元的支持。 ")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 位于长沙市岳麓区大科城中南大学科技园研发总部的湖南新云网科技有限公司，与邻居中南大学建立了深度的产学研合作关系。公司以“透明计算”技术为基础，融合 5G、大数据、人工智能等技术， 充分依托高校的技术力量和优势，创新性的在智慧教育和智慧医疗领域发明了几十款智能终端产品，形成独有的智慧教育体系和智慧医疗体系！ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"10px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news4/news2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin-top":"20px"}},[_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 这款智慧校牌是连接学校、老师、家长之间的桥梁。不仅是校园一卡通，还能打电话，实时在线答题，满足学生在校多种需求。智慧健康亭则可以在半小时以内完成人体的常规体检，出具包含饮食、运动等方面建议的报告单，并向不同健康状态的居民提供健康评估、健康指导、诊疗指导等精准的健康服务并出具健康报告。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box",staticStyle:{"margin-top":"30px"}},[_c('img',{attrs:{"src":require("../../assets/images/news/news4/news3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-content",staticStyle:{"margin":"20px 0 40px"}},[_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" 这款无感式动态心电贴，在患者身上佩戴，贴在靠近心脏的位置， 可通过 4G、5G 信号将检测数据实时传输到云计算平台以及手机上，医生在云计算平台可实时观看到患者的检测数据，做到有问题早发现、早治疗，患者可通过手机APP 查看本人的检测报告和医生诊断建议。 ")])])
}]

export { render, staticRenderFns }